import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/DD/YYYY',
  },
  display: {
    dateInput: 'MM/DD/YYYY', // The format you want for the input
    monthYearLabel: 'MMM YYYY', // Format for displaying month and year
    dateA11yLabel: 'LL', // Accessibility label format for dates
    monthYearA11yLabel: 'MMMM YYYY', // Accessibility label format for month and year
  },
};

import 'moment/locale/en-gb';
import 'moment/locale/ru'; 

import * as moment from 'moment';
moment.fn.toJSON = function() { return this.format(); }
 

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MatDatepickerModule,
    MatNativeDateModule 
  ],
  exports: [
    MatDatepickerModule,
    MatNativeDateModule
  ],
  providers: [  
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }, 
  ]
})
export class CustomDateAdapterModule { } 